import React, { useState, useEffect, Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Routes } from '../routes'

// pages
import DashboardOverview from './dashboard/DashboardOverview'
import Transactions from './Transactions'
import Orders from './Orders'

// components
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Preloader from '../components/Preloader'
import AllCourse from './AllCourse'
import CreateCourse from './components/CreateCourse'
import EditCourse from './components/EditCourse'
import AllChapters from './AllChapters'
import AllLessons from './AllLessons'
import AllQuiz from './AllQuiz'
import CreateChapter from './components/CreateChapter'
import CreateLesson from './components/CreateLesson'
import CreateQuiz from './components/CreateQuiz'
import EditChapter from './components/EditChapter'
import EditQuiz from './components/EditQuiz'
import EditLesson from './components/EditLesson'
import ComingSoon from './components/ComingSoon'
import Webinar from './components/Webinar'
import AllStudents from './AllStudents'
import Scholarship from './Scholarship'
import ViewStudent from './components/ViewStudent'
import ViewScholarship from './components/ViewScholarship'
import Logger from './Logger'
import FEEDBACK from './Feed-back'
import QuestionAnswers from './Question-Answers'
import Comments from './Comments'
import Coupon from './Coupon'
import axios from 'axios'
import FreeWebinar from './FreeWebinar'
import PaidWebinar from './PaidWebinar'
import ViewFreeWebinar from './components/ViewFreeWebinar'
import ViewPaidWebinar from './components/ViewPaidWebinar'
import CreateTypoForm from './components/CreateTypoForm'
import EditTypoForm from './components/EditTypoForm'
import ViewQuestions from './components/ViewQuestions'
import AllTypoFormList from './AllTypoFormList'
import AllNewsLetter from './AllNewsLetter'
import AnswerList from './AnswerList'
import ViewAnswer from './components/ViewAnswer'
import UserReportList from './UserReportList'
import TransactionReportList from './TransactionReportList'
import ViewReportUser from './components/ViewReportUser'
import AllCoach from './AllCoach'
import QuizScore from './QuizScore'
import CreateCoach from './components/CreateCoach'
import EditCoach from './components/EditCoach'
import Chat from './Chat'
import { InAppPurchase } from './InAppPurchase'
import { CrateInAppPurchaseCode } from './components/CrateInAppPurchaseCode'
import { EditInAppPurchase } from './components/EditInAppPurchase'
import { ViewInAppPurchase } from './components/ViewInAppPurchase'
import InAppPurchaseUser from './InAppPurchaseUser'
import ViewInAppPurcahseUsers from './components/ViewInAppPurcahseUsers'
import Notification from './Notification'
import CreateFreeCourse from './components/CreateFreeCourse'
import AllFreeCourse from './AllFreeCourse'
import EditFreeCourse from './components/EditFreeCourse'
import CreateFreeLesson from './components/CreateFreeLesson'
import AllFreeLessons from './AllFreeLessons'
import EditFreeLesson from './components/EditFreeLesson'

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT

// const RouteWithLoader = ({ component: Component, ...rest }) => {
//   const [loaded, setLoaded] = useState(false)

//   useEffect(() => {
//     const timer = setTimeout(() => setLoaded(true), 1000)
//     return () => clearTimeout(timer)
//   }, [])

//   return (
//     <Route
//       {...rest}
//       render={props => (
//         <>
//           {' '}
//           <Preloader show={loaded ? false : true} /> <Component {...props} />{' '}
//         </>
//       )}
//     />
//   )
// }

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000)
    return () => clearTimeout(timer)
  }, [])

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible)

  const toggleSettings = () => {
    setShowSettings(!showSettings)
    localStorage.setItem('settingsVisible', !showSettings)
  }

  return (
    <Route
      {...rest}
      render={props => (
        <>
          <Preloader show={loaded ? false : true} />
          <Sidebar />

          <main className='content' style={{ height: '100vh !important' }}>
            <Navbar />
            <Component {...props} />
            <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
          </main>
        </>
      )}
    />
  )
}

export default () => (
  <Switch>
    <RouteWithSidebar exact path={Routes.Signin.path} component={DashboardOverview} />
    <RouteWithSidebar exact path='/all-courses' component={AllCourse} />
    <RouteWithSidebar exact path='/all-free-courses' component={AllFreeCourse} />
    <RouteWithSidebar exact path='/edit/:id' component={EditCourse} />
    <RouteWithSidebar exact path='/edit-free/:id' component={EditFreeCourse} />
    <RouteWithSidebar exact path='/edit-typo-from/:id' component={EditTypoForm} />
    <RouteWithSidebar exact path='/view-question-from/:id' component={ViewQuestions} />
    <RouteWithSidebar exact path='/edit-coach/:id' component={EditCoach} />
    <RouteWithSidebar exact path='/user-report-view/:id' component={ViewReportUser} />
    <RouteWithSidebar exact path='/view-answer/:id' component={ViewAnswer} />
    <RouteWithSidebar exact path='/in-app-purchase-user/:id' component={ViewInAppPurcahseUsers} />
    <RouteWithSidebar exact path='/free-webinar' component={FreeWebinar} />
    <RouteWithSidebar exact path='/transactions' component={Transactions} />
    <RouteWithSidebar exact path='/orders' component={Orders} />
    <RouteWithSidebar exact path='/paid-webinar' component={PaidWebinar} />
    <RouteWithSidebar exact path='/notification' component={Notification} />
    <RouteWithSidebar exact path='/view-free-webinar/:id' component={ViewFreeWebinar} />
    <RouteWithSidebar exact path='/view-paid-webinar/:id' component={ViewPaidWebinar} />

    <RouteWithSidebar exact path='/mychapters/:id' component={AllChapters} />
    <RouteWithSidebar exact path='/edit-chapter/:id' component={EditChapter} />

    <RouteWithSidebar exact path='/all-lessons/:id' component={AllLessons} />
    <RouteWithSidebar exact path='/create-lesson/:id' component={CreateLesson} />
    <RouteWithSidebar exact path='/all-free-lessons/:id' component={AllFreeLessons} />
    <RouteWithSidebar exact path='/create-free-lesson/:id' component={CreateFreeLesson} />
    <RouteWithSidebar exact path='/create-quiz/:id' component={CreateQuiz} />
    <RouteWithSidebar exact path='/edit-quiz/:id/:id2' component={EditQuiz} />

    <RouteWithSidebar exact path='/all-quiz/:id' component={AllQuiz} />

    <RouteWithSidebar exact path='/create-course' component={CreateCourse} />
    <RouteWithSidebar exact path='/create-free-course' component={CreateFreeCourse} />
    <RouteWithSidebar exact path='/create-typo-form' component={CreateTypoForm} />
    <RouteWithSidebar exact path='/create-coach' component={CreateCoach} />
    <RouteWithSidebar exact path='/coupon' component={CreateCourse} />
    <RouteWithSidebar exact path='/create-chapter/:id' component={CreateChapter} />
    <RouteWithSidebar exact path='/create-in-app-purchase' component={CrateInAppPurchaseCode} />
    <RouteWithSidebar exact path='/Edit-in-app-purchase/:id' component={EditInAppPurchase} />
    <RouteWithSidebar exact path='/view-in-app-purchase/:id' component={ViewInAppPurchase} />
    <RouteWithSidebar exact path='/edit-lesson/:id' component={EditLesson} />
    <RouteWithSidebar exact path='/edit-free-lesson/:id' component={EditFreeLesson} />

    <RouteWithSidebar exact path='/answer/course' component={ComingSoon} />

    <RouteWithSidebar exact path='/tutors' component={ComingSoon} />
    <RouteWithSidebar exact path='/add-new-tutor' component={ComingSoon} />
    <RouteWithSidebar exact path='/remove-tutor' component={ComingSoon} />

    <RouteWithSidebar exact path='/answer/feedbacks' component={ComingSoon} />

    <RouteWithSidebar exact path='/live/webinar' component={Webinar} />
    <RouteWithSidebar exact path='/live/chat' component={ComingSoon} />

    <RouteWithSidebar exact path='/all-students' component={AllStudents} />
    <RouteWithSidebar exact path='/all-typo-form' component={AllTypoFormList} />
    <RouteWithSidebar exact path='/all-news-letter' component={AllNewsLetter} />
    <RouteWithSidebar exact path='/coach-list' component={AllCoach} />
    <RouteWithSidebar exact path='/quiz-score' component={QuizScore} />
    <RouteWithSidebar exact path='/chat' component={Chat} />
    <RouteWithSidebar exact path='/in-app-purchase-user' component={InAppPurchaseUser} />
    <RouteWithSidebar exact path='/all-answer' component={AnswerList} />
    <RouteWithSidebar exact path='/in-app-purchase' component={InAppPurchase} />
    <RouteWithSidebar exact path='/user-report' component={UserReportList} />
    <RouteWithSidebar exact path='/transaction-report' component={TransactionReportList} />
    <RouteWithSidebar exact path='/create-coupon' component={Coupon} />
    <RouteWithSidebar exact path='/shah-logger' component={Logger} />
    <RouteWithSidebar exact path='/feed-back' component={FEEDBACK} />
    <RouteWithSidebar exact path='/question-answers' component={QuestionAnswers} />
    <RouteWithSidebar exact path='/comments' component={Comments} />
    <RouteWithSidebar exact path='/all-students/view/:id' component={ViewStudent} />

    <RouteWithSidebar exact path='/scholarship' component={Scholarship} />
    <Suspense fallback={<div>Loading...</div>}>
      <RouteWithSidebar exact path='/scholarship-view/:id' component={ViewScholarship} />
    </Suspense>

    {/* pages */}

    {/* <RouteWithSidebar exact path='/transactions' component={Transactions} /> */}
    {/* <RouteWithSidebar exact path={Routes.Logger.path} component={Logger} /> */}
    {/* <RouteWithSidebar exact path={Routes.Coupon.path} component={Coupon} /> */}
    {/* <RouteWithSidebar exact path='/create-coupon' component={Coupon} /> */}
    <Redirect from='/all-chapters' to='/all-courses' />

    <Redirect to={Routes.NotFound.path} />
  </Switch>
)
