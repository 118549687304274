import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { FreeLessonTable } from '../components/Tables'

export default props => {
  const id = props.match.params.id
  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0'>
          <h4 onClick={() => window.history.back()}>
            <FontAwesomeIcon icon={faArrowLeft} style={{ cursor: 'pointer' }} /> All Free Lessons
          </h4>
          <p className='mb-0'>
            SHAH lessons management or <a href={'#/create-free-lesson/' + id}> Create Free Lesson</a>
          </p>
        </div>
      </div>

      <FreeLessonTable course_id={id} />
    </>
  )
}
